export function getThemeUrl(url: string, useConcept: boolean): string {
    if (useConcept) {
        const concept = sessionStorage.getItem("conceptCustomer")
        if (!!concept) {
            url = url.replace(/(\/.+?\/).+?(\/.+?\..+)/gi, `$1${concept}$2`)
        }
    }
    return url.replace(/(.*)styles\.css$/gi, "$1theme.json")
}

export function getMuiThemeUrl(url: string, useConcept: boolean): string {
    if (useConcept) {
        const concept = sessionStorage.getItem("conceptCustomer")
        if (!!concept) {
            url = url.replace(/(\/.+?\/).+?(\/.+?\..+)/gi, `$1${concept}$2`)
        }
    }
    return url.replace(/(.*)styles\.css$/gi, "$1theme-mui.json")
}
