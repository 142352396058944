import { LoginMatch } from "../login/matchLoginUrl"

const CATALOG_KEY = "catalog"
const DEFAULT_CATALOG = "default"

export function getCatalog(loginMatch: LoginMatch | undefined): string {
    if (loginMatch) {
        return loginMatch.catalog || getSavedCatalog()
    }

    return getSavedCatalog()
}

export function getSavedCatalog() {
    return sessionStorage.getItem(CATALOG_KEY) || localStorage.getItem(CATALOG_KEY) || DEFAULT_CATALOG
}

export function clearSavedCatalog() {
    localStorage.removeItem(CATALOG_KEY)
    sessionStorage.removeItem(CATALOG_KEY)
}
