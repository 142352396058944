import { CompleteFileUploadRequest, CompleteFileUploadResponse } from "./models"

export async function completeFileUpload({ baseUrl, uploadRequestId, uploadKey }: CompleteFileUploadRequest) {
    const url = `${baseUrl}/uploads/requests/${uploadRequestId}?key=${uploadKey}`
    const response = await fetch(url, { method: "POST" })
    if (response.ok) {
        return (await response.json()) as CompleteFileUploadResponse
    }

    return undefined
}
