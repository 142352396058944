export type GetUploadKeyResponse = {
    id: string
    uploadKey: string
    uploadUrl: string
    parts: UploadRequestPart[]
}

export type UploadRequestPart = {
    id: string
    position: string
    description: string
    allowedMimeTypes: string[]
    allowedExtensions: string[]
    minimumCount: number
    maximumCount: number
    files: UploadRequestPartFile[]
}

export type UploadRequestPartFile = {
    id: string
    fileIndex: string
    meta: FileMeta
}

export type FileMeta = {
    id: string
    mimeType: string
    fileSize: number
    displayName: string
    prefix: string
    userPrefix: string
    meta: DynamicMetaProperty
    url: string
    description: string
    expiresAt: Date
    createdAt: Date
    updatedAt: Date
    commited: boolean
    kind: FileKind
    labels: Array<string>
    thumbnail: Thumbnail
}

type DynamicMetaProperty = {
    [key: string]: string
}

export type Thumbnail = {
    available: boolean
    id: string
    url: string
}

export enum FileKind {
    Document,
    Image,
    Video,
    Thumbnail,
    Other,
    Directory,
    Audio,
    Archive,
}

export type ShareAccessKey = {
    id: string
    key: string
    userId: string
    validFrom: Date
    validTo: Date
    Timestamps: Date
    directUrl: string
    directFileUrls: string[]
    downloadPageUrl: string
    // shareFile: ShareFile
    labels: string[]
}

export type UploadFileInfo = {
    category?: string
    area?: string
    workshopUser?: string
    driver?: string
    vehicleId?: string
    vin?: string
    mileage?: string
    sum?: string
}
