import { ExternalToken } from "./models"

export function getCachedToken(itemKey: string) {
    const tokenItem = sessionStorage.getItem(itemKey)

    if (tokenItem) {
        const token = JSON.parse(tokenItem) as ExternalToken
        if (Date.now() < token.expiration * 1000) {
            // unix timestamp * 1000 to compare with js timestamp
            return token
        }
        sessionStorage.removeItem(itemKey)
    }
}

export function cacheToken(itemKey: string, token: ExternalToken) {
    sessionStorage.setItem(itemKey, JSON.stringify(token))
}
