import { ReactNode } from "react"
import { atom } from "recoil"
import { SnackbarProvider } from "./SnackbarProvider"
import { useSnackbar } from "./useSnackbar"

export type SnackbarType = "warning" | "info" | "error" | "success"
export type SnackbarMessage = {
    id: string
    type: SnackbarType
    autoClose: boolean
    isCloseable: boolean
    content: ReactNode
    icon?: ReactNode
}

export const snackbarState = atom<SnackbarMessage[]>({ key: "SNACKBAR", default: [] })

export { SnackbarProvider, useSnackbar }