import { UploadSingleImageRequest } from "./models"

export async function uploadSingleImage(request: UploadSingleImageRequest) {
    const { baseUrl, file, uploadRequestId, partId, fileIndex, uploadKey } = request
    const formData = new FormData()

    formData.append("formFile", file)

    const uploadUrl = `${baseUrl}/uploads/${uploadRequestId}/parts/${partId}/${fileIndex}?key=${uploadKey}`

    const response = await fetch(uploadUrl, { method: "POST", body: formData })
    if (response.ok) {
        return true
    }

    return false
}
