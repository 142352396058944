// This only exists for the NEXT DEV tool and should be removed after the tool was changed somehow
// Maybe integrate the code of the tool into the portal but only load it using dynamic imports when dev mode is enabled

import * as Csx from "csx"
import * as React from "react"
import * as ReactDOM from "react-dom"
import * as Redux from "redux"
import * as ReactRedux from "react-redux"
import * as RxJs from "rxjs"

declare global {
    interface Window {
        BrowserBuffer: typeof Buffer
        Csx: typeof Csx
        History: typeof History
        React: typeof React
        ReactDOM: typeof ReactDOM
        ReactRedux: typeof ReactRedux
        Redux: typeof Redux
        RxJs: typeof RxJs
    }
}

window.BrowserBuffer = Buffer
window.Csx = Csx
window.History = History
window.React = React
window.ReactDOM = ReactDOM
window.ReactRedux = ReactRedux
window.Redux = Redux
window.RxJs = RxJs
