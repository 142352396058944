import { clone, ajaxAuth } from "@tm/utils"
import { SubmitInputRequest, SubmitInputResponse } from "./models"
import { mapFastCalculatorResponse } from "../helpers/mapData"
import { WebserviceUrl } from "../../webserviceUrls"

export function submitInput(request: SubmitInputRequest) {
    const url = `${WebserviceUrl.FastCalculator}/SubmitInput`
    const body = clone(request)
    body.parentCalcState.context = undefined

    return ajaxAuth<SubmitInputResponse>({ url, body, method: "POST" }).then((data) => {
        const mappedData = mapFastCalculatorResponse(data)
        if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
            return mappedData
        }

        throw new Error()
    })
}
