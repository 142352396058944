import { ajaxAuth } from "@tm/utils"
import { GetSharedAccessKeyRequest } from "./models"
import { ShareAccessKey } from "../../erikContentService"
import { WebserviceUrl } from "../../webserviceUrls"

export function getSharedAccessKey(body: GetSharedAccessKeyRequest) {
    const url = `${WebserviceUrl.Vefiles}/GetSharedAccessKey`

    return ajaxAuth<ShareAccessKey>({ url, body }, undefined, undefined, true)
}
