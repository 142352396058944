import { useState, ReactNode } from "react"
import { Layout } from "@tm/morpheus"
import { Button } from "@tm/controls"
import { getCurrentWorkTaskId } from "@tm/models"
import { getSidebarStyles } from "./styles"
import { style } from "typestyle"


export default class SidebarLayout extends Layout {
    constructor(props: any) {
        super(props)
    }

    render() {
        return <Sidebar>{this.renderSlot(0)}</Sidebar>
    }
}

function Sidebar(props: { children: ReactNode|ReactNode[]|null|undefined }) {
    const [isHidden, setHidden] = useState<boolean>(false)

    const id = getCurrentWorkTaskId()
    let cssLocation = !id ? " layout-sidebar__start" : " layout-sidebar__worktask"
    cssLocation = !id && location.pathname != "/" ? " layout-sidebar__no-worktask" : cssLocation

    const handleSidebarVisibility = () => {
        setHidden(!isHidden)
    }

    let sidebarClassExtension = `${isHidden ? " layout-sidebar--hidden": ""}`
    const buttonClassExtension = `${isHidden ? " button__sidebar--hidden": ""}`

    return <div className={style(getSidebarStyles()) + " layout layout-sidebar" + cssLocation + sidebarClassExtension} >
            {props.children}
            <Button
                layout={["holo"]}
                icon={isHidden ? "arrow-right" : "arrow-left"}
                onClick={handleSidebarVisibility}
                size="m"
                className={"button__sidebar" + buttonClassExtension}
            />
    </div>
}
