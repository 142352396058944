import { getStoredAuthorization, ajax, createBufferedRequestFunction } from "@tm/utils"
import { Container } from "@tm/nexus"
import { RegisteredModels, ViewState } from "@tm/models";

let serviceBaseUrl: string

export function initialize(baseUrl: string) {
    serviceBaseUrl = baseUrl
    Container.register<ViewState>({
        name: RegisteredModels.ViewState,
        modelActions: {
            load: loadViewState,
            save: (state: ViewState, merge?: boolean) => saveViewState(state, merge).then(),
        },
        containerActions: {
            loadViewState: loadViewState,
            saveViewState: saveViewState,
        }
    })
}

type LoadJsonStateListResponse = {
    values?: Array<{ id: string, value: any }>
}

export const loadViewState = createBufferedRequestFunction({
    callService: loadJsonStateList,
    mapServiceResponseToResponse: (serviceResponse, request): ViewState => ({
        key: request,
        value: serviceResponse?.values?.find(response => response.id === request)?.value,
    }),
    compareRequestsForFiltering: (a, b) => a === b,
})

function loadJsonStateList(ids: Array<string>) {
    const url = `${serviceBaseUrl}/LoadJsonStateList`
    const authorization = getStoredAuthorization()
    const body = { ids: ids.map(id => ({ id })) }

    return ajax<LoadJsonStateListResponse>({ url, body, authorization, method: "POST" })
}


let saveStateQueue: {
    [id: string]: {
        state: {}
        merge?: boolean
        resolves: Array<() => void>
        rejects: Array<(error: unknown) => void>
    }
} = {}
let bufferTimeout: number

export function saveViewState(state: ViewState, merge?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
        if (!state) return

        clearTimeout(bufferTimeout)

        const existingState = saveStateQueue[state.key] as typeof saveStateQueue[string] | undefined
        saveStateQueue[state.key] = {
            state: typeof state.value === "object" ? {
                ...(existingState?.state || {}),
                ...state.value,
            } : state.value,
            merge,
            resolves: [
                ...(existingState?.resolves || []),
                resolve,
            ],
            rejects: [
                ...(existingState?.rejects || []),
                reject,
            ],
        }
        bufferTimeout = window.setTimeout(performSave, 1000)
    })
}

function performSave() {
    if (!Object.keys(saveStateQueue).length)
        return

    const url = `${serviceBaseUrl}/SaveStateList`
    const authorization = getStoredAuthorization()

    const resolves: Array<() => void> = []
    const rejects: Array<(error: unknown) => void> = []
    const body = {
        stateList: Object.keys(saveStateQueue).map(id => {
            resolves.push(...saveStateQueue[id].resolves)
            rejects.push(...saveStateQueue[id].rejects)

            const { state, merge } = saveStateQueue[id]
            return {
                id,
                state,
                merge,
            }
        })
    }

    saveStateQueue = {}

    return ajax({ url, body, authorization, method: "POST" }).then(() => {
        resolves.forEach(resolve => resolve())
    }, error => {
        rejects.forEach(reject => reject(error))
    })
}
