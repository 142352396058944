import { ToastMessageHandler } from "./handler/toastmessages"
import { ConfirmationMessageHandler } from "./handler/confirmation"


const handlers = [
    {Component: ToastMessageHandler, key: "ToastMessageHandler"},
    {Component: ConfirmationMessageHandler, key: "ConfirmationRequestHandler"}
]
/***
 * AppMessageCenter
 *
 * add a message handler to handlers. it has to handle all the message positioning by himself.
 * AppMessageCenter will be rendered inside the modals, but if you want to add it to another position, just use createPortal (it should work :)
 *
 * [Example]
 * open the console and call
 * __NEXT_MESSAGEBUS__.channel("APP").publish("TOAST_MESSAGE/SHOW", {message: "Hello Mr. Fubar" })
 * __NEXT_MESSAGEBUS__.channel("APP").publish("TOAST_MESSAGE/SHOW", {message: "Hello Mr. Fubar", icon:"customer", skin:"success", closeDelay: 3000})
 * __NEXT_MESSAGEBUS__.channel("APP").publish("TOAST_MESSAGE/SHOW", {message: "Hello Mr. Fubar", icon:"customer", skin:"success" })
 * __NEXT_MESSAGEBUS__.channel("APP").publish("TOAST_MESSAGE/SHOW", {message: "Hello Mr. Fubar", icon:"customer", skin:"success", closeDelay: false})
 */
export function AppMessageCenter() {
    return <>{handlers.map(Handler => <Handler.Component key={Handler.key} />)}</>
}
