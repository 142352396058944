import { History } from "history"
import { SystemType, channel } from "@tm/models"
import { Container } from "@tm/nexus"
import { ajax, getStoredAuthorization, removeStoredLogin } from "@tm/utils"
import { removeBrowserCompatibilityCheckFlag } from "../../components/BrowserCompabilityCheck"
import { redirectToLogin } from "../login/redirectToLogin"
import { removeBrowserTabId } from "../logging/setBrowserTabId"
import { AppConfig } from "../data/loadConfiguration"

export function registerLogoutContainerAction(history: History, config: AppConfig, catalog: string) {
    Container.register({
        name: "Portal",
        containerActions: {
            logout: (redirect: string | boolean = true, callService = true, rememberCurrentUrl = true) => {
                let promise = Promise.resolve()

                if (callService) {
                    promise = ajax({
                        url: `${config.login.serviceUrl}/Logout`,
                        authorization: getStoredAuthorization(),
                    })
                }

                return promise.finally(() => {
                    try {
                        // NEXT Shell should also be informed that the user is logged out
                        if (typeof (tmJSEvents) != "undefined" && typeof (tmJSEvents.logout) == "function") {
                            tmJSEvents.logout()
                        }

                        removeStoredLogin()
                        removeBrowserTabId()
                        removeBrowserCompatibilityCheckFlag()

                        // if the portal is used in another System (SC/RD) module we have to notify the external system about the logout
                        const userContext = channel("GLOBAL").last(1, "USER/CONTEXT_LOADED")?.[0]?.content?.context
                        if (userContext?.system.systemType == SystemType.Redesign || userContext?.system.systemType == SystemType.Smartclient) {
                            window.parent.postMessage({ nextLogOut: true }, "*")
                        }
                    }
                    finally {
                        if (redirect) {
                            if (typeof redirect == "string") {
                                location.replace(redirect)
                            }
                            else {
                                if (config.logout?.url) {
                                    location.replace(config.logout.url)
                                }
                                else {
                                    redirectToLogin(history, catalog, rememberCurrentUrl)
                                }
                            }
                        }
                    }
                })
            }
        }
    })
}
