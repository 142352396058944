// This only exists for the NEXT DEV tool and should be removed after the tool was changed somehow
// Maybe integrate the code of the tool into the portal but only load it using dynamic imports when dev mode is enabled

import * as TmContextDistribution from "@tm/context-distribution"
import * as TmControls from "@tm/controls"
import * as TmModels from "@tm/models"
import * as TmMorpheus from "@tm/morpheus"
import * as TmUtils from "@tm/utils"

declare global {
    interface Window {
        TmContextDistribution: typeof TmContextDistribution
        TmControls: typeof TmControls
        TmModels: typeof TmModels
        TmMorpheus: typeof TmMorpheus
        TmUtils: typeof TmUtils
    }
}

window.TmContextDistribution = TmContextDistribution
window.TmControls = TmControls
window.TmModels = TmModels
window.TmMorpheus = TmMorpheus
window.TmUtils = TmUtils
