import { AppConfig } from "../data/loadConfiguration"

export function setupProgressiveWebApp(config: AppConfig) {
    registerServiceWorker(config)

    // create pwa manifest
    if (config.pwa) {
        createPwaManifest(config)
    }
}

async function registerServiceWorker(config: AppConfig) {
    if (!("serviceWorker" in navigator)) {
        return
    }

    // install serviceworker only for pwa catalogs
    if (config.pwa) {
        try {
            await navigator.serviceWorker.register("../service-worker.js")
        }
        catch (err) {
            console.log("ServiceWorker registration failed: ", err)
        }
    } else {
        const workers = await navigator.serviceWorker.getRegistrations()

        for (const worker of workers) {
            try {
                worker.unregister()
            }
            catch (err) {
                console.log("ServiceWorker unregistration failed: ", err)
            }
        }
    }
}

function createPwaManifest(appConfig: AppConfig) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`

    const pwaManifest = {
        "short_name": appConfig.pwa?.shortName || appConfig.title,
        "name": appConfig.pwa?.name || appConfig.title,
        "lang": "de",
        "icons": [
            {
                "src": baseUrl + appConfig.pwa?.icons[512],
                "sizes": "512x512",
                "type": "image/png",
            },
            {
                "src": baseUrl + appConfig.pwa?.icons[128],
                "sizes": "128x128",
                "type": "image/png",
            },
        ],
        "start_url": baseUrl + (appConfig.pwa?.startUrl || ""),
        "display": "fullscreen",
        "theme_color": appConfig.pwa?.themeColor,
        "background_color": appConfig.pwa?.backgroundColor,
        "orientation": "natural",
        "scope": baseUrl + (appConfig.pwa?.scope || "/"),
    }

    const stringManifest = JSON.stringify(pwaManifest)
    const manifestURL = URL.createObjectURL(new Blob([stringManifest], { type: "application/json" }))

    document.querySelector("#manifest-placeholder")?.setAttribute("href", manifestURL)
}
