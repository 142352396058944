import { ajaxAuth } from "@tm/utils"
import { CalcProductGroupsRequest, CalcProductGroupsResponse } from "./models"
import { mapFastCalculatorResponse } from "../helpers/mapData"
import { WebserviceUrl } from "../../webserviceUrls"

export function calcProductGroups(request: CalcProductGroupsRequest) {
    const url = `${WebserviceUrl.FastCalculator}/CalcProductGroups`
    const body = request

    return ajaxAuth<CalcProductGroupsResponse>({ url, body, method: "POST" }).then((data) => {
        const mappedData = mapFastCalculatorResponse(data)
        if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
            return mappedData
        }

        throw new Error()
    })
}
