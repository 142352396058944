import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import deepAssign from "deep-assign"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()

    const component = {
        masterLayoutBackground: "#333333",
        masterLayoutBody: "#fafafa",
        sidebarWidget: {
            fore: color(colors.light).setAlpha(opacity.primary).toRgbString()
        },
        sidebar: {
            back: "transparent"
        },
        border: {
            radius: "3px"
        }
    }

    const themeStyles: NestedCSSProperties = {
        backgroundColor: component.masterLayoutBackground,
        $nest: {
            ".layout-sidebar__worktask": {
                $nest: {
                    ".layout__slot-0": {
                        paddingTop: "7em"
                    }
                }
            },
            ".layout-sidebar__no-worktask": {
                $nest: {
                    ".layout__slot-0": {
                        paddingTop: "7em"
                    }
                }
            },
            ".sidebar": {
                background: component.sidebar.back,
                height: "unset",
                padding: "1.2em",
                $nest: {
                    "&-title": {
                        $nest: {
                            "&-content": {
                                color: color(colors.light).setAlpha(opacity.primary).toRgbString(),
                                fill: component.sidebarWidget.fore,
                            },
                            "&-icon": {
                                opacity: .9
                            }
                        }
                    },

                    "&-content": {
                        $nest: {
                            ".search-container__hint": {
                                display: "none !important"
                            }
                        }
                    }
                }
            },
            "&-content": {
                $nest: {
                    ".text": {
                        color: (colors as any).masterLayoutBody
                    },

                    ".sidebar-icon": {
                        fill: color(colors.light).setAlpha(0.9).toRgbString(),
                        $nest: {
                            ".icon": {
                                opacity: 1
                            }
                        }
                    },

                    ".btn__icon": {
                        height: "1.25em",
                        width: "1.25em"
                    }
                }
            },
            ".button__sidebar": {
                $nest: {
                    ".btn__icon.icon": {
                        fill: component.masterLayoutBody
                    },

                    "&:hover": {
                        $nest: {
                            ".btn__icon.icon": {
                                fill: component.masterLayoutBody
                            }
                        }
                    }
                }
            },
            ".inputgroup .inputgroup__children.input--textfield:not(.suggest--active)": {
                borderRight: "1px solid rgba(195, 195, 195, 0.8)"
            }
        }
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
