import { ajax, getStoredAuthorization } from "@tm/utils"
import { LocalizationConfig } from "@tm/localization"

const TRANSLATION_APP_ID = 86

export async function getCatalogTexts(baseUrl: string, _authId: string | { default: string } | undefined, languageId: string): Promise<LocalizationConfig["translation"]> {
    const res = await ajax({
        url: `${baseUrl}/GetTexts`,
        body: {
            appId: TRANSLATION_APP_ID,
            languageId,
        },
        authorization: getStoredAuthorization(),
    })

    if (res && typeof res === "string") {
        return JSON.parse(res)
    }

    return res
}

export async function getCatalogTextsLogin(baseUrl: string, auth: string | { default: string } | undefined, languageId: string): Promise<LocalizationConfig["translation"]> {
    const res = await ajax({
        url: `${baseUrl}/GetTextsWithoutToken`,
        body: {
            appId: TRANSLATION_APP_ID,
            languageId,
            authId: getAuthId(auth),
        }
    })

    if (res && typeof res === "string") {
        return JSON.parse(res)
    }

    return res
}

function getAuthId(auth: string | { default: string } | undefined): string | undefined {
    if (!auth) {
        return
    }

    if (typeof auth === "string") {
        return auth
    }

    if (typeof auth === "object" && auth.default) {
        return auth.default
    }
}
