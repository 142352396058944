import { History } from "history"
import { createQueryString } from "@tm/utils"

export function redirectToLogin(history: History, catalog: string, rememberCurrentUrl: boolean = true) {
    const { pathname, search } = history.location

    let redirect = rememberCurrentUrl ? (pathname.replace(/^\//, "") + search) || undefined : undefined
    if (redirect === "usersettings") {
        redirect = undefined
    }

    let trader = sessionStorage.getItem("traderId") || undefined
    if (trader) {
        sessionStorage.removeItem("traderId")
    }

    const query = createQueryString({
        trader,
        redirect,
    })

    let loginUrl = `/login/${catalog}`

    if (query) {
        loginUrl = `${loginUrl}${query}`
    }

    window.location.replace(loginUrl)
}
