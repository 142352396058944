import { ajaxAuth } from "@tm/utils"
import { FastCalcRequest, FastCalculatorInitResponse } from "./models"
import { mapFastCalculatorResponse } from "../helpers/mapData"
import { WebserviceUrl } from "../../webserviceUrls"

export function initFastCalc(request: FastCalcRequest) {
    const url = `${WebserviceUrl.FastCalculator}/FastCalc`
    const body = { dataIn: request, vehicleId: request.vehicleId }

    return ajaxAuth<FastCalculatorInitResponse>({ url, body, method: "POST" }).then((data) => {
        const mappedData = mapFastCalculatorResponse(data)
        if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
            return mappedData
        }

        throw new Error()
    })
}
