import { Layout } from "@tm/morpheus"

export default class ThreeSlotLayout extends Layout {
    render() {
        return (
            <div className="layout layout--three-slot">
                {this.renderSlot(0)}
                {this.renderSlot(1)}
                {this.renderSlot(2)}
            </div>
        )
    }
}
