import { useRef, useEffect, useState } from "react"
import { MessageToast } from "@tm/controls"
import { AppChannel } from "../helpers"
import { useLocalization } from "@tm/localization"
/***
 * @param onCloseHandler { Function } will be execute if the user hits the close button
 * @param closeDelay {number | false} set to false will disable auto close
 */
export type ShowMessageProps = {
    message: string
    icon?: string
    skin?: "success" | "warning" | "danger"
    onCloseHandler?: () => void
    className?: string
    closeDelay?: false | number
}

const ANIMATECLOSE_CLASSNAME = "closing"

export function ToastMessageHandler() {
    const { translateText, isTranslationId } = useLocalization()
    const [messages, setMessages] = useState<{ props: ShowMessageProps, uniqueKey: string }[]>([])

    useEffect(() => {
        const subscriptionHandler = (_props: ShowMessageProps) => {
            setMessages((prevMessages) => {
                const uniqueKey = `${Date.now()}__${_props.message.replace(/\W/gm, '')}`

                if (_props.closeDelay !== false) {
                    setTimeout(() => {
                        startCloseAnimation(uniqueKey)
                    }, _props.closeDelay || (3 * 1000))
                }

                return [...prevMessages, {
                    uniqueKey,
                    props: {
                        ..._props,
                    }
                }]
            })
        }

        AppChannel.subscribe("TOAST_MESSAGE/SHOW", (messageProps) => { subscriptionHandler(messageProps) })
    }, [])

    const messagesRef = useRef(messages) // useRef is the only option to access the state inside timeout/interval
    messagesRef.current = messages

    const startCloseAnimation = (key: string) => { // will start animation by extending classname with "closing"
        if (messagesRef.current) {
            const newMessages = messagesRef.current.map((message) => {
                if (message.uniqueKey == key) {
                    message.props.className = message.props.className ? `${message.props.className} ${ANIMATECLOSE_CLASSNAME}` : ANIMATECLOSE_CLASSNAME
                }
                return message
            })
            setMessages(newMessages)
        }
    }

    const processClose = (key: string) => { // will be called when the animation will end, triggered by onTransitionEnd
        setMessages(messagesRef.current.filter((message) => {
            return message.uniqueKey != key
        }))
    }

    return <div className="modal__messages" style={{ contain: "layout" }}>
        {
            messages.map((message) => {
                const { props } = message
                const { className } = props

                const clickHandler = () => {
                    startCloseAnimation(message.uniqueKey)
                    props.onCloseHandler && props.onCloseHandler()
                }

                let remover: any
                return <div
                    key={`wrapper${message.uniqueKey}`}
                    className={className}
                    onTransitionEnd={(event) => {
                        if (className && className.indexOf(ANIMATECLOSE_CLASSNAME) >= 0) {
                            remover && clearTimeout(remover)
                            remover = setTimeout(() => processClose(message.uniqueKey), 50)
                        }
                    }}>
                    <MessageToast
                        skipPortal={true}
                        key={message.uniqueKey}
                        onButtonClick={clickHandler}
                        message={isTranslationId(props.message) ? translateText(props.message) : props.message}
                        skin={props.skin}
                        icon={props.icon || "info"}
                    />
                </div>
            })
        }
    </div>
}
