import { Dialog, Button, Stack, Box } from "@tm/components"
import { useLocalization } from "@tm/localization"

export default function Error412() {
    const { translate } = useLocalization()
    const handleClick = () => location.reload()

    return (
        <Dialog open skin="warning" position="top">
            <Stack spacing={2}>
                {translate(1187)}
                <Box>
                    <Button size="large" color="highlight" onClick={handleClick}>
                        {translate(1188)}
                    </Button>
                </Box>
            </Stack>
        </Dialog>
    )
}
