import { getStoredAuthorization, ajax, createBufferedRequestFunction } from "@tm/utils"
import { Container } from "@tm/nexus"
import { RegisteredModels } from "@tm/models";

let serviceBaseUrl: string

export function initialize(baseUrl: string) {
    serviceBaseUrl = baseUrl
    Container.register({
        name: RegisteredModels.KeyValueStore,
        containerActions: {
            loadKeyValue: (id: string, ownedByRepairShop = false) => loadKeyValue({ id, ownedByRepairShop }),
            saveKeyValue,
            deleteKeyValue,
        }
    })
}

function deleteKeyValue(id: string, ownedByRepairShop = false) {
    const url = `${serviceBaseUrl}/DeleteKeyValue`
    const authorization = getStoredAuthorization()
    const body = { id, ownedByRepairShop }

    return ajax({ url, body, authorization, method: "POST" })
}


function saveKeyValue(id: string, value?: string, ownedByRepairShop = false) {
    const url = `${serviceBaseUrl}/SaveKeyValue`
    const authorization = getStoredAuthorization()
    const body = { id, value, ownedByRepairShop }

    return ajax({ url, body, authorization, method: "POST" })
}

type LoadKeyValueRequest = {
    id: string,
    ownedByRepairShop: boolean
}

const loadKeyValue = createBufferedRequestFunction({
    callService: loadKeyValueList,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return serviceResponse?.find(response => response.id === request.id)?.value || ""
    }
})

function loadKeyValueList(requests: Array<LoadKeyValueRequest>) {
    const url = `${serviceBaseUrl}/LoadKeyValueList`
    const authorization = getStoredAuthorization()
    const body = { ids: requests }

    return ajax<{ values?: Array<{ id: string, value: string }> }>({ url, body, authorization, method: "POST" })
        .then(response => response?.values)
}
