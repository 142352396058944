import { ajaxAuth, uniqueId } from "@tm/utils"
import { CostEstimation, CostEstimationVoucherCustomer, CostEstimationVoucherVehicle } from "./model"

export function showDetails(body: ShowDetailsRequest) {
    // TODO configurable cost-estimation:  Rename to ShowDetails when the original ShowDetails is no longer used
    const url = "/data/TM.Next.Vouchers/Vouchers/ShowDetailsV2"
    return ajaxAuth<ShowDetailsResponse>({ url, body }, undefined, undefined, true).then((response) => {
        if (response) {
            return mapShowDetailsResponse(response)
        }
    })
}

export type ShowDetailsRequest = {
    id: string
}

export type ShowDetailsResponse = {
    id: string
    workTaskId: string
    workTaskNumber: string
    voucherNumber: string
    creationDate: Date
    customer?: CostEstimationVoucherCustomer
    vehicle?: CostEstimationVoucherVehicle
    totalNumbers?: TotalNumbers
    repairShopLogo?: string
    costEstimation?: CostEstimation
}

type TotalNumbers = {
    partsCount: number
    partsPositionCount: number
    worksPositionCount: number
}

function mapShowDetailsResponse(response: ShowDetailsResponse): ShowDetailsResponse {
    return {
        ...response,
        costEstimation: {
            ...response.costEstimation,
            parts: response.costEstimation?.parts?.map((part) => {
                // Some old Vouchers don't have this property in the DB
                return { ...part, partItemId: part.partItemId ?? uniqueId() }
            }),
        },
    }
}
