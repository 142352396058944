export type Manifest = {
    name: string
    version: string
    isUpdating?: boolean
    isQuietUpdate?: boolean
}

export function loadManifest(): Promise<Manifest> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.onloadend = () => {
            if (xhr.status != 200 || !xhr.getResponseHeader("Content-Type")?.includes("application/json")) {
                reject()
                return
            }

            try {
                const manifest = JSON.parse(xhr.responseText)

                if (manifest?.version) {
                    resolve(manifest)
                }
                else {
                    reject()
                }
            }
            catch {
                reject()
                return
            }
        }

        xhr.open("GET", "/manifest.json")
        xhr.send()
    })
}
