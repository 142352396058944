import { BaseTrackingModel, TmaEventTracking } from "@tm/utils"

export function handleLoginSuccess(catalog: string) {
    localStorage.setItem("catalog", catalog)

    Object.keys(sessionStorage).forEach(key => {
        if (key !== "login" && key !== "traderId") {
            sessionStorage.removeItem(key)
        }
    })

    sessionStorage.setItem("catalog", catalog)
}

const eventprops: BaseTrackingModel<any> & { singleEvent: boolean } = {
    action: "bootstrap",
    bundle: "portal",
    component: "login",
    tmaEvent: "logged_in",
    source: "",
    payload: "hidden",
    type: "",
    singleEvent: true
}

export function handleTmaLogin(loginType: "hidden" | "form", disableAnalytics: boolean): Promise<void> {
    if (disableAnalytics) {
        return Promise.resolve()
    }

    return new Promise(resolve => {
        const promise = TmaEventTracking.dispatchTrack({ ...eventprops, payload: loginType })

        const alwaysLogin = setTimeout(() => resolve(), 1500)

        promise?.finally(() => {
            clearTimeout(alwaysLogin)
            resolve()
        })
    })
}
