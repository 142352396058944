export enum ECalcStateButtonIcon {
    None = 0,
    Basket = 1,
    Bin = 2,
    Resolved = 3,
    Replace = 4,
    DropDownArrow = 5,
    Add = 6,
    TMSuggestion = 7,
    NoFavorite = 8,
    IsFavorite = 9,
    ArtList = 10,
    UniSearch = 11,
    OeSearch = 12,
    FastTD = 13,
}

export enum ECalcButtonState {
    None = 0,
    Submit = 1,
    Reset = 2,
    AddToBasket = 3,
    CreateQuote = 4,
    PrintMaintenancePlan = 5,
    FastCockpit = 6,
    VehicleType = 7,
    MaintenanceSystem = 8,
    Interval = 9,
    Calculation = 10,
    AdditionalWork = 11,
    FollowupWork = 12,
    DeletePart = 13,
    DeleteRepairTime = 14,
    DeleteConsumable = 15,
    DeleteConsumablePart = 16,
    Close = 17,
    IncludeSuggestedWorks = 18,
    OpenProvider = 19,
    Link = 20,
    ToCalculationNext = 21,
    CreateKva = 22,
    FastCockpitNext = 23,
    CalculationNext = 24,
    StartCalculation = 25,
    StartNewCalculation = 27,
    SaveCalculation = 28,
}

export enum ECalcButtonStyle {
    None,
    Default,
    Success,
    Danger,
    Warning,
    Info,
    Primary,
    Secondary,
    Outlined,
}

export enum ECalcCmd {
    None,
    SubmitInitialDataCalcState,
    SubmitFastCockpitCalcState,
    SubmitVehicleTypeCalcState,
    SubmitMaintenanceSystemCalcState,
    SubmitIntervalCalcState,
    SelectSelectionItem,
    ResetCalculation,
    SetActiveCalcState,
    SubmitMileageInput,
    SubmitRegDateInput,
    SubmitEngineCodeInput,
    SubmitLonglifeInput,
    SubmitCalculateIncludedAdditionalWorkInput,
    SubmitPriceInput,
    SubmitQuantityInput,
    SubmitReplaceArticleByAlternativeInput,
    SubmitReplaceOeArticleByAlternativeInput,
    SubmitRemoveArticleInput,
    SubmitRemoveOeArticleInput,
    SubmitRemoveGenArtInput,
    SubmitRemoveConsumableInput,
    SubmitRemoveRepairTimeInput,
    SubmitAdditionalWorkCalcState,
    SubmitFollowupWorkCalcState,
    AddPartFromArticleList,
    SubmitGlobalLabourRate,
    SubmitConsumableSpecificationDropDownClick,
    SubmitConsumableLabelDropDownClick,
    SubmitEngineCodeDropDownItemClick,
    SubmitConsumableLabelInput,
    SubmitRemoveDropDownInput,
    SubmitFavoriteDropDownInput,
    ClickIncludeSuggestedWorksButton,
    SubmitCrossDeleteAcceptInput,
    SubmitSwitchOeGroupInput,
    SetActiveTopProductgroups,
    SubmitMaintenancePlanOverlayInput,
    SubmitFastCockpitNextCalcState,
    ServerSideCmd,
    DestroyCalc,
}

export enum EDropDownItem {
    ConsumableSpecification,
    ConsumableLabel,
    EngineCode,
}

export enum ECalcInputStyle {
    None = 0,
    Text = 1,
    Number = 2,
    Slider = 3,
    Checkbox = 4,
    Date = 5,
    Button = 6,
    DropDown = 7,
    Multiline = 8,
    MonthYearDate = 9,
}

export enum ECalcInputType {
    None,
    Mileage,
    RegDate,
    EngineCode,
    Longlife,
    CalculateIncludedAdditionalWork,
    ArticlePrice,
    OeArticlePrice,
    GenArtPrice,
    ConsumablePrice,
    RepairTimePrice,
    ArticleQuantity,
    OeArticleQuantity,
    GenArtQuantity,
    ConsumableQuantity,
    RepairTimeQuantity,
    ReplaceArticleByAlternative,
    ReplaceArticleByOeAlternative,
    RemoveArticle,
    RemoveOeArticle,
    RemoveGenArt,
    RemoveConsumable,
    RemoveRepairTime,
    GlobalLabourRate,
    ConsumableSpecifications,
    ConsumableLabel,
    RemoveDropDown,
    FavoriteDropDown,
    CrossDeleteAccept,
    CrossDeleteDeny,
    SwitchOeGroup,
    CustomerName,
    CustomerAddress,
    LicensePlate,
    VIN,
    MaintenancePlanOverlayMileage,
    NextMaintenanceDate,
    NextMaintenanceMileage,
    NextGenInspectionDate,
    PrintTires,
    PrintFirstAid,
    PrintTiresSealant,
    PrintConsumableQuantities,
    PrintComment,
    Comment,
    MaintenancePlanOverlayInitialRegistration,
    PrintInspectionTimes,
    Something,
    History,
    ArticleInput,
}

export enum ECalcInputValue {
    None = 0,
    Bool = 1,
    String = 2,
    Int = 3,
    Long = 4,
    DateTime = 5,
    Decimal = 6,
    Article = 7,
}

export enum ECalcInputValueModifier {
    None = 0,
    Uppercase = 1,
    Cut = 2,
}

export enum ECalcInputValueValidator {
    None = 0,
    WarnZero = 1,
    Max = 2,
    Min = 3,
}

export enum EPropertyType {
    EngineCode = 0,
    Period = 1,
    IsLonglife = 2,
}

export enum ESearchLink {
    None = 0,
    Uni = 1,
    Direct = 2,
    FastTD = 3,
}

export enum ECalcState {
    None = 0,
    InitialData = 1,
    FastCockpit = 1 << 1,
    VehicleType = 1 << 2,
    MaintenanceSystem = 1 << 3,
    Interval = 1 << 4,
    Calculation = 1 << 5,
    AdditionalWork = 1 << 6,
    FollowupWork = 1 << 7,
    FastPackages = 1 << 8,
    MaintenancePlanOverlay = 1 << 9,
    MaintenancePlanPrint = 1 << 10,
    Basket = 1 << 11,
    Quote = 1 << 12,
    ServiceProvider = 1 << 13,
    GenArtPrices = 1 << 14,
    CrossDeleteDialog = 1 << 15,
    PlaceHolderDialog = 1 << 16,
    Error = 1 << 17,
    Log = 1 << 18,
    FastCockpitPreviewNext = 1 << 19,
    LastCalcPreviewNext = 1 << 20,
    FastCockpitNext = 1 << 21,
    CalculationNext = 1 << 22,
    AlternativeCalcArticles = 1 << 23,
    BasketNext = 1 << 24,
    QuoteNext = 1 << 25,

    /// <summary>
    ///   Combination of all presentable calc states
    /// </summary>
    PresentableCalcState = FastCockpit |
        VehicleType |
        MaintenanceSystem |
        Interval |
        Calculation |
        LastCalcPreviewNext |
        FastCockpitPreviewNext |
        FastCockpitNext,
    /// <summary>
    ///   Combination of all widget calc states
    /// </summary>
    WidgetCalcState = FastCockpit | VehicleType | MaintenanceSystem | Interval | Calculation | LastCalcPreviewNext | FastCockpitPreviewNext,

    /// <summary>
    ///   Combination of all overlay calc states
    /// </summary>
    OverlayCalcStates = AdditionalWork |
        FollowupWork |
        FastPackages |
        MaintenancePlanOverlay |
        AlternativeCalcArticles |
        Interval |
        MaintenanceSystem |
        VehicleType,

    WorksState = FollowupWork | AdditionalWork,

    /// <summary>
    ///   Combination of all data calc states
    /// </summary>
    DataCalcStates = MaintenancePlanPrint | Basket | Quote | ServiceProvider | GenArtPrices | BasketNext | QuoteNext,

    /// <summary>
    ///   Combination of all dialog calc states
    /// </summary>
    DialogCalcStates = CrossDeleteDialog | PlaceHolderDialog,

    /// <summary>
    ///   Combination of all calc state combinations
    /// </summary>
    AllCalcStates = InitialData | PresentableCalcState | OverlayCalcStates | DataCalcStates | DialogCalcStates,
}

export enum ECalcWorkflow {
    Theia,
    Hyperion,
}
