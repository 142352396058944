import { Headline } from "@tm/controls";
import { useLocalization } from "@tm/localization"

export function ComponentError() {
    const { translate } = useLocalization()

    return (
        <div className="component-error">
            <Headline size="xs">{translate(12424)}</Headline>
            {translate(12425)}
        </div>
    )
}
