import { Layout } from "@tm/morpheus"

export default class OneSlotLayout extends Layout {
    render() {
        return (
            <div className="layout layout--one-slot">
                {this.renderSlot(0)}
            </div>
        )
    }
}