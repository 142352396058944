import { ajaxAuth, clone } from "@tm/utils"
import { AddPartFromArticleListRequest, AddPartFromArticleListResponse } from "./models"
import { mapFastCalculatorResponse } from "../helpers/mapData"
import { WebserviceUrl } from "../../webserviceUrls"

export function addPartFromArticleList(request: AddPartFromArticleListRequest) {
    const url = `${WebserviceUrl.FastCalculator}/AddPartFromArticleList`
    const body = clone(request)
    body.parentCalcState.context = undefined

    return ajaxAuth<AddPartFromArticleListResponse>({ url, body, method: "POST" }).then((data) => {
        const mappedData = mapFastCalculatorResponse(data)
        if (mappedData?.selectedCalcState?.context || mappedData?.isQueued) {
            return mappedData
        }

        throw new Error()
    })
}
