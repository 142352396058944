import { ComponentType, Suspense, lazy } from "react"
import { LoginFactory } from "../../logins"
import { AppConfig } from "../data/loadConfiguration"
import { LoginLoader } from "../data/loadingProgress"
import { handleLoginSuccess, handleTmaLogin } from "./handleLoginOutcome"
import { LoginMatch } from "./matchLoginUrl"
import { trySingleSignOnAndRedirect } from "./trySingleSignOn"

export function getLoginComponents(appConfigor: AppConfig, loginMatch: LoginMatch, catalog: string, languageId: string, loginServiceUrl: string): [ComponentType, ComponentType | undefined] | undefined {
    if (!appConfigor.login.url) {
        return
    }

    const { disableAnalytics } = appConfigor.params

    if (trySingleSignOnAndRedirect(loginMatch, languageId, catalog, disableAnalytics)) {
        return
    }

    const LoginWrapper = getLoginWrapper(appConfigor.login.url, { ...appConfigor.login.props, redirect: loginMatch.redirect, serviceUrl: loginServiceUrl }, catalog, disableAnalytics)

    if (!appConfigor.login.errorLogin) {
        return [LoginWrapper, undefined]
    }

    const LoginErrorWrapper = getLoginErrorWrapper(appConfigor.login.errorLogin, { ...appConfigor.login.props, redirect: loginMatch.redirect, serviceUrl: loginServiceUrl }, catalog)

    return [LoginWrapper, LoginErrorWrapper]
}

type LoginProps = AppConfig["login"]["props"] & {
    serviceUrl: string
    redirect: string | undefined
}

function getLoginWrapper(url: string, loginProps: LoginProps, catalog: string, disableAnalytics: boolean) {
    const Login = lazy(() => LoginFactory(url))

    const LoginWrapper = () => {
        return (
            <Suspense fallback={<LoginLoader />}>
                <Login
                    {...loginProps}
                    catalog={catalog}
                    onSuccess={() => handleLoginSuccess(catalog)}
                    onBeforeRedirect={() => handleTmaLogin("form", disableAnalytics)}
                />
            </Suspense>
        )
    }

    return LoginWrapper
}

function getLoginErrorWrapper(url: string, loginProps: LoginProps, catalog: string, ) {
    const LoginError = lazy(() => LoginFactory(url))

    const LoginErrorWrapper = () => {
        return (
            <Suspense fallback={<LoginLoader />}>
                <LoginError {...loginProps} type="login-error" catalog={catalog} />
            </Suspense>
        )
    }

    return LoginErrorWrapper
}
