import { ajax } from "@tm/utils"
import { CheckQueuedCalculationsResponse } from "./models"

export async function checkQueuedCalculations(checkStatusUrl: string): Promise<CheckQueuedCalculationsResponse> {
    const url = `${checkStatusUrl}`

    return ajax<number>({ url, method: "GET" }).then((data) => {
        if (data === 2) {
            return { done: true }
        }

        return { done: false }
    })
}
