import { ajax } from "@tm/utils"

export async function loadBaseConfig() {
    const config = await ajax<BaseConfig>({ url: "/config.json" })

    if (!config) {
        throw new Error("Base configuration (file: /config.json) could not be loaded.")
    }

    return config
}

export type BaseConfig = {
    service: string
    systems?: string[]
}
