import { Layout, LayoutProps } from "@tm/morpheus"

type TwoSlotProps = {
    hideWidget?: boolean
}

export default class TwoSlotLayout extends Layout<TwoSlotProps> {

    constructor(props: LayoutProps<TwoSlotProps>) {
        super(props)
    }

    render() {

        if (this.props.props?.hideWidget) {
            return null

        }
        return (
            <div className="layout layout--two-slot">
                {this.renderSlot(0)}
                {this.renderSlot(1)}
            </div>
        )
    }
}