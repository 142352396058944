import { AppConfig } from "../data/loadConfiguration"
import { UserContext } from "@tm/models"

export function setDocumentMetadata(config: AppConfig, userContext : UserContext) {
    document.title = config.title

    const faviconElement = document.querySelector<HTMLLinkElement>("#favicon")

    if (faviconElement) {  
        const dealerIconUrl = getDealerIconUrlFromConfig(config.login.props?.dealerIconUrl)     
        faviconElement.href = userContext?.parameter.dealerIconUrl || dealerIconUrl || config.favicon
    }   
}

function getDealerIconUrlFromConfig(entry: string | undefined){
    if (entry && !entry.includes("Placeholder not found")) {
        return entry
    }
    return undefined
}
