import { hasValidStoredLogin } from "@tm/utils"
import { History } from "history"
import { clearSavedCatalog, getCatalog } from "../document/currentCatalogStorage"
import { getLanguageId } from "../localization/getLanguageId"
import { handleExternalAuthentication } from "../login/externalAuthentication"
import { handleAnonymousLogin } from "../login/handleAnonymousLogin"
import { LoginMatch, matchLoginUrl } from "../login/matchLoginUrl"
import { redirectToLogin } from "../login/redirectToLogin"
import { BaseConfig } from "./loadBaseConfig"
import { AppConfig, ConfigType, loadConfiguration } from "./loadConfiguration"

export type AppCatalogConfig = {
    appConfig: AppConfig
    catalog: string
    loginMatch: LoginMatch | undefined
    configType: ConfigType
    languageId: string
} | undefined

export async function handleConfiguration(history: History<unknown>, baseConfig: BaseConfig): Promise<AppCatalogConfig> {
    const loginMatch = matchLoginUrl(history)
    const catalog = getCatalog(loginMatch)
    const configType: ConfigType = loginMatch ? "LOGIN" : "APP"

    if (!loginMatch && !hasValidStoredLogin()) {
        // If user is not logged in, go to login page
        redirectToLogin(history, catalog)
        return
    }

    const appConfig = await loadConfiguration(baseConfig, configType, catalog, location.hostname, loginMatch?.traderId)

    if (!appConfig) {
        clearSavedCatalog()

        if (!location.pathname.startsWith("/login")) {
            // TODO: check if "redirectToLogin(catalog)" could be used here
            history.replace("/login")
            return await handleConfiguration(history, baseConfig)
        }

        return
    }

    const languageId = getLanguageId(appConfig, loginMatch)

    if (appConfig.login?.props?.anonymousLogin) {
        const successfulLogin = await handleAnonymousLogin(catalog, appConfig, languageId)

        if (successfulLogin) {
            history.replace(`/${loginMatch?.redirect || ""}`)
            return await handleConfiguration(history, baseConfig)
        }
    }

    if (await handleExternalAuthentication(appConfig, history, languageId, catalog)) {
        return
    }

    return { appConfig, catalog, loginMatch, configType, languageId }
}
