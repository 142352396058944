import { Layout } from "@tm/morpheus"
import { classes, style } from 'typestyle'
import { LocalizationProps, withLocalization } from "@tm/localization"
import { withUserContext, WithUserContextProps } from "@tm/context-distribution"

class DashboardOptionTwo extends Layout {
    render() {
        const props = this.props as any as WithUserContextProps & LocalizationProps

        const urlLogoMoodPicture = props.userContext?.parameter.urlLogoMoodPicture
        const headlineClassName = !!urlLogoMoodPicture && style({ backgroundImage: 'url(' + urlLogoMoodPicture + ') !important' })

        return (
            <div className="layout layout--dashboard-option-two">
                <div className={classes("lnt-banner", headlineClassName)}>
                    {this.renderSlot(4)}
                </div>
                <div className="lnt-widgets">
                    <div className="lnt-widgets__wrapper">
                        <div className="lnt-widgets__content">
                            <div className="logo"></div>
                            <div className="headline headline--m mt-3 mb-5">{props.localization.translate(415)}</div>
                            {this.renderSlot(0)}
                            {this.renderSlot(1)}
                            {this.renderSlot(2)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withUserContext(withLocalization(DashboardOptionTwo as any) as any)
