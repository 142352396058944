import "core-js/stable"
import "regenerator-runtime/runtime"
import "url-search-params-polyfill"
import "whatwg-fetch"

if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
        value: function (callback: BlobCallback, type?: string, quality?: any) {
            setTimeout(() => {
                const binStr = atob(this.toDataURL(type, quality).split(",")[1])
                const len = binStr.length
                const arr = new Uint8Array(len)

                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }

                callback(new Blob([arr], { type: type || "image/png" }))
            })
        }
    })
}
