import { uniqueId } from "@tm/utils"

const ID_KEY = "browserTabId"
const STATUS_KEY = "pagehideStatus"

/**
 * @memberof Portal
 * visibilitychange could be used, but Safari doesnt trigger the event on visibility state change
 * pagehide will not be triggered by chrome Ôó
 * pagehide has to be used too
 */
function tabFocusHandler() {
    sessionStorage.setItem(STATUS_KEY, `${window.document.hasFocus()}`)
}

window.addEventListener("visibilitychange", tabFocusHandler)
window.addEventListener("pagehide", tabFocusHandler)

/***
 * @memberof Portal
 * If an tab is duplicated the complete sessionStorage is duplicated
 * This would lead to invalid tma workflows because two
 * */
export function setBrowserTabId(forceNewId?: boolean) {
    const storedTabId = sessionStorage.getItem(ID_KEY)
    const isInFocus = sessionStorage.tabInFocus === "true"
    const tabId = !forceNewId && storedTabId && isInFocus ? storedTabId : uniqueId()

    sessionStorage.setItem(ID_KEY, tabId)
    sessionStorage.setItem(STATUS_KEY, "true")
}

export function removeBrowserTabId() {
    sessionStorage.removeItem(ID_KEY)
}
