import { OrderVouchersShowSupplierArticleByVehicleRequest, OrderVouchersShowSupplierArticleByVehicleResponse } from "@tm/models"
import { ajaxAuth } from "@tm/utils"

const baseUrl = "/data/TM.Next.Vouchers/Orders"

export function showSupplierArticleByVehicle(body: OrderVouchersShowSupplierArticleByVehicleRequest) {
    const url = `${baseUrl}/ShowSupplierArticleByVehicle`

    return ajaxAuth<OrderVouchersShowSupplierArticleByVehicleResponse>({ url, body }, undefined, undefined, true)
}
