import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"

export function getSidebarStyles(): NestedCSSProperties {
    const { animation } = getStyleTheme()
    const component ={
        back: "transparent"
    }

    const styles: NestedCSSProperties = {
        backgroundColor: component.back,
        position: "relative",

        $nest: {
            ".layout__slot-0": {
                flexDirection: "column",
                width: "312px",
                transition: animation.transition
            },
            "&.layout-sidebar--hidden": {
                $nest: {
                    ">.layout__slot-0": {
                        opacity: 0,
                        width: "2.4em"
                    }
                }
            },
            ".button__sidebar": {
                position: "absolute",
                right: "1.2rem",
                top: "3rem",
                transition: animation.transition,

                $nest: {
                    "&--hidden": {
                        right: "4px"
                    }
                }
            },
            ".sidebar:first-child": {
                paddingTop: "3.2rem"
            }
        }
    }

    return merge("getItemStyles", styles)
}

function merge(getStylesFn: string, baseStyles: NestedCSSProperties): NestedCSSProperties {
    const { name } = getStyleTheme()
    let getStyles: any
    try {
        const pack = require(`./themes/${name}`) 
        getStyles = pack ? pack[getStylesFn] : undefined
    } catch {}
    return getStyles ? getStyles(baseStyles) : baseStyles
}
