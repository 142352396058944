export function getStylesUrl(url: string, useConcept: boolean): string {
    if (useConcept) {
        const concept = sessionStorage.getItem("conceptCustomer")
        if (!!concept) {
            return url.replace(/(\/.+?\/).+?(\/.+?\..+)/gi, `$1${concept}$2`)
        }
    }

    return url
}
