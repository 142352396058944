import { ReactNode } from "react"
import { render } from "react-dom"
import { AssetLoadProgress } from "@tm/morpheus"

function CatalogNotFound(props: { catalog: string }) {
    return (
        <div className="config-error">
            Catalog <strong>{props.catalog}</strong> not found.
        </div>
    )
}

export function handleConfigurationNotFound(catalog: string) {
    render(
        <CatalogNotFound catalog={catalog} />,
        document.querySelector("#splash-screen")
    )
}

function Loader(props: { children: ReactNode }) {
    return (
        <div className="asset-loader">
            {props.children}
        </div>
    )
}

export function ConfigurationLoader() {
    return <Loader>Loading configuration...</Loader>
}

export function LoginLoader() {
    return <Loader>Loading login...</Loader>
}

export function AssetLoader(props: { loadedCount: number, totalCount: number }) {
    return <Loader>Loading modules: {props.loadedCount} ({props.totalCount})</Loader>
}

export function handleConfigurationLoadProgress() {
    render(
        <ConfigurationLoader />,
        document.querySelector("#splash-screen")
    )
}

export function handleAssetLoadProgress(progress: Array<AssetLoadProgress>, totalCount?: number) {
    render(
        <AssetLoader loadedCount={progress.filter(x => x.ready).length} totalCount={totalCount ?? 0} />,
        document.querySelector("#splash-screen")
    )
}

export function resetLoadProgress() {
    const container = document.querySelector("#splash-screen")

    if (container?.firstChild) {
        container.removeChild(container.firstChild)
    }
}
