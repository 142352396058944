import { LogEntry, RegisteredModels } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { initialize } from "../../repositories/logging"

export function initLogging() {
    initialize()

    Morpheus.addErrorListener(error => Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", { type: "Component error", error }))
}
