import { History, Location } from "history"

export function initPortalLocations(history: History) {
    window.portalLocations = {
        history: [],
        current: history.location
    }

    history.listen((location: Location) => {
        window.portalLocations.current && window.portalLocations.history.unshift(window.portalLocations.current)
        window.portalLocations.current = location
        window.portalLocations.history.length > 10 && window.portalLocations.history.splice(11)
    })
}
